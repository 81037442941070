<template>
  <div>
    <el-button v-if="gateClosable" @click="centerDialogVisible = true" type="warning" plain size="mini">点击落杆</el-button>
    <el-button
      v-if="gateClosable || showOpenGate"
      type="primary"
      size="mini"
      :loading="requestLoading"
      @click="gateAction('controlgate')"
    >点击抬杆</el-button
    >
    <el-dialog
      :title="gateData ? gateData.devicename : ''"
      :visible.sync="centerDialogVisible"
      width="600px"
      center>
      <el-alert
        :closable="false"
        title="警告"
        type="warning"
        description="为确保安全，请在使用落杆功能前，务必确认出入口周围无人、无车通过。操作落杆时，请仔细观察现场情况，避免造成人员伤害或财产损失。"
        show-icon>
      </el-alert>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="gateAction('camera-out2')" :loading="requestLoading" size="small">我已知晓，并落杆</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    gateData: Object,
    showOpenGate: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      centerDialogVisible: false,
      requestLoading: false,
      gateClosable: false
    }
  },
  mounted () {
    this.baseUrl = this.$store.state.baseUrl// 请求base地址
    const parkInfo = this.$store.state.parkConfig.parkInfo || {}

    this.gateClosable = parkInfo.gateClosable === '1'
  },
  methods: {

    async gateAction (url) {
      try {
        this.requestLoading = true
        const userId = window.localStorage.getItem('LOGIN_USERID')
        var { data: res } = await this.$http.get(this.baseUrl + url, {
          params: {
            deviceNo: this.gateData.devicecode,
            userId: userId,
            parkCode: window.localStorage.getItem('LOGIN_PACKCODE')
          }
        })
        res = JSON.parse(res)
        if (res.code === '0') {
          this.centerDialogVisible = false
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      } catch (e) {
        this.$message.error(e.toString())
      } finally {
        this.requestLoading = false
      }
    }
  }
}
</script>
